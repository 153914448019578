/* src/components/MakePicks.css */

.make-picks-container {
  padding: 40px;
  max-width: 1100px;
  margin: auto;
  background: #f7f9fc;
  border-radius: 15px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.make-picks-container:hover {
  background-color: #f0f4fa;
}

h2 {
  text-align: center;
  font-size: 40px;
  margin-bottom: 30px;
  color: #2a3d55;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Detailed and polished section styling */
.selection-section {
  margin-bottom: 50px;
  padding: 25px;
  border-radius: 15px;
  background: linear-gradient(135deg, #ffffff 0%, #f0f4f9 100%);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.selection-section:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.15);
}

.selection-section h3 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #1f3a57;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-weight: 600;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
}

/* Enhanced team selection layout */
.team-selection {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
}

.team-slot {
  position: relative;
  z-index: 1;
  width: 150px;
  height: 190px;
  border: 2px solid #e0e0e0;
  border-radius: 15px;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.team-slot:hover {
  transform: translateY(-7px);
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.2);
  border-color: #007bff;
}

.team-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 15px;
}

.team-info img {
  width: 75px;
  height: 75px;
  border-radius: 8px;
  object-fit: cover;
  margin-bottom: 12px;
  transition: transform 0.2s ease;
}

.team-slot:hover .team-info img {
  transform: scale(1.1);
}

.team-info span {
  font-size: 18px;
  font-weight: 600;
  color: #2c3e50;
  letter-spacing: 0.5px;
}

.ranking-number {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
}

/* Search box styling */
.search-box {
  position: absolute;
  top: -50px;
  left: 0;
  width: 320px;
  padding: 20px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  border: 1px solid #e0e0e0;
  animation: fadeIn 0.3s ease forwards;
}

/* Smooth fade-in for the search box */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Enlarged and interactive search result items */
.search-result-item {
  display: flex;
  align-items: center;
  gap: 18px;
  padding: 14px 18px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
  border-radius: 10px;
}

.search-result-item img {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.2s ease;
}

.search-result-item:hover {
  background-color: #007bff;
  color: white;
  transform: translateY(-3px);
}

.search-result-item:hover img {
  transform: scale(1.1);
}

.search-box input {
  width: 100%;
  padding: 14px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
  outline: none;
  transition: border 0.3s ease, box-shadow 0.3s ease;
}

.search-box input:focus {
  border: 1px solid #007bff;
  box-shadow: 0px 0px 10px rgba(0, 123, 255, 0.5);
}

/* Enhanced search results container */
.search-results {
  max-height: 280px;
  overflow-y: auto;
  margin-top: 12px;
  border-top: 1px solid #ccc;
}

/* Enhanced button styles */
button {
  padding: 12px 18px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

button:hover {
  background-color: #0056b3;
  transform: translateY(-5px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .team-selection {
    flex-direction: column;
    align-items: center;
  }

  .team-slot {
    width: 100%;
    max-width: 320px;
  }

  .selection-section {
    padding: 20px;
  }
}

/* Finals section container */
.finals-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

/* Finals team selection */
.finals {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}

/* Finals team (Winner and Loser) */
.finals-team {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
}

/* Label for Winner and Loser */
.finals-label {
  font-size: 18px;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 10px;
}

/* "vs" label in the center */
.vs-label {
  font-size: 30px;
  font-weight: bold;
  color: #333;
  margin: 0 20px;
  text-align: center;
}

/* Spin the Wheel button */
.spin-the-wheel-button {
  font-size: 20px;
  padding: 15px 30px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;
}

.spin-the-wheel-button:hover {
  background-color: #0056b3;
  transform: scale(1.1);
}

/* Wheel container */
.spin-the-wheel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

/* Animation Overlay */
.player-animation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.player-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.animated-player-image {
  width: 300px;
  height: auto;
  animation: zoomIn 1s forwards;
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

/* Hover up and down after animation */
.animated-player-image.hover {
  animation: floatUpDown 2s infinite;
}

@keyframes floatUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.confirm-button {
  font-size: 18px;
  padding: 10px 25px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.confirm-button:hover {
  background-color: #218838;
  transform: translateY(-3px);
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .finals {
    flex-direction: column;
  }

  .vs-label {
    margin: 20px 0;
  }
}

.golden-tint {
  background-color: rgba(255, 215, 0, 0.2);
  transition: background-color 0.5s ease-in-out;
}

@media (max-width: 430px) {
  /* Reduce the padding inside the main container */
  .make-picks-container {
    padding: 20px;
  }

  /* Make the headings smaller for better readability on small screens */
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .selection-section h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  /* Adjust the team selection layout to fit better on smaller screens */
  .team-selection {
    gap: 10px;
  }

  .team-slot {
    width: 100px;
    height: 140px;
  }

  /* Adjust the image size and text inside the team-info */
  .team-info img {
    width: 50px;
    height: 50px;
    margin-bottom: 8px;
  }

  .team-info span {
    font-size: 14px;
  }

  /* Adjust ranking number size */
  .ranking-number {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }

  /* Finals section adjustments */
  .finals-section {
    margin-bottom: 30px;
  }

  .finals-team {
    width: 150px;
  }

  .finals-label {
    font-size: 16px;
  }

  /* Adjust the "vs" label in the center for small screens */
  .vs-label {
    font-size: 24px;
    margin: 10px 0;
  }
}
