/* Main header container */
.header-container {
  background: linear-gradient(135deg, #1e3c72 0%, #2a5298 50%, #202a43 100%);
  padding: 20px 0px;
  box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.25);
  position: relative;
  top: 0;
  z-index: 1000;
  width: 100%;
  overflow: hidden;
}

/* Flexbox for header content */
.header-content {
  position: relative;
  width: 100%;
  height: 150px; /* Adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Title styling */
.header-title {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  font-size: 42px;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 1.2px;
  text-shadow: 4px 4px 14px rgba(0, 0, 0, 0.5);
  position: relative; /* To position placeholders */
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2; /* Adjusted for better spacing */
}

.header-title span,
.header-title h1 {
  display: inline-block;
}

/* Basketball Placeholder */
.basketball-placeholder {
  display: inline-block;
  width: 50px; /* Adjust to match basketball size */
  height: 50px;
  vertical-align: middle;
}

/* Hoop Placeholder */
.hoop-placeholder {
  display: inline-block;
  width: 50px; /* Adjust to match hoop size */
  height: 50px;
  vertical-align: middle;
}

/* Subtitle styling */
.header-subtitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  color: #c9d6ff;
  margin: 0;
  letter-spacing: 0.7px;
}

/* Left-aligned Home button */
.go-home-button {
  background-color: rgba(255, 255, 255, 0.2);
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  text-decoration: none;
  color: white;
  transition: all 0.3s ease;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 5px;
  top: 15px;
}

.go-home-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.4), 0px 0px 20px rgba(255, 255, 255, 0.8);
  transform: translateY(-5px);
}

.go-home-button .home-icon {
  font-size: 40px;
  color: #ffffff;
  transition: transform 0.3s ease;
}

.go-home-button:hover .home-icon {
  transform: rotate(15deg);
}

/* Right-aligned button section */
.header-right {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Stacked buttons container */
.stacked-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 120px;
}

/* Common button style for stacked buttons */
.nav-button,
.legacy-button,
.logout-button {
  padding: 11.5px 66px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Button hover effects */
.nav-button:hover,
.legacy-button:hover,
.logout-button:hover {
  transform: translateY(-3px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
}

/* Legacy button specific styles */
.legacy-button {
  background-color: #e7d881;
  color: black;
}

.legacy-button:hover {
  background-color: #ffc107;
}

/* Scoring button specific styles */
.nav-button {
  background-color: #007bff;
  color: white;
}

.nav-button:hover {
  background-color: #0056b3;
}

/* Logout button specific styles */
.logout-button {
  background-color: #dc3545;
  color: white;
}

.logout-button:hover {
  background-color: #c82333;
}

/* Basketball */
.basketball {
  position: absolute;
  cursor: pointer;
  font-size: 50px;
  user-select: none;
  z-index: 10;
}

/* Basketball Hoop */
.hoop {
  position: absolute;
  width: 150px;
  height: 150px;
}

/* Hoop Image */
.hoop-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: translate(5px, -50px);
}

/* Block styles */
.block {
  position: absolute;
  width: 10px; /* Adjust width as needed */
  height: 50px; /* Adjust height as needed */
  background-color: brown; /* Color of the blocks */
}

.left-block {
  left: 50px; /* Adjust to position the block relative to the hoop */
  top: 70px; /* Adjust as needed */
}

.right-block {
  right: 50px; /* Adjust to position the block relative to the hoop */
  top: 70px; /* Adjust as needed */
}

/* Static basketballs for mobile */
.static-basketball {
  display: inline-block;
  font-size: 30px;
  margin: 0 10px; /* Adjust spacing as needed */
}

/* MEDIA QUERIES */

/* For devices with width up to 768px */
@media only screen and (max-width: 768px) {
  /* Adjust header padding and spacing */
  .header-container {
    padding: 15px 10px;
  }

  /* Reduce header height */
  .header-content {
    height: auto;
    flex-direction: column;
    padding: 10px 0;
  }

  /* Reduce title font size */
  .header-title {
    font-size: 28px;
    flex-wrap: wrap;
    line-height: 1.2;
  }

  /* Adjust static basketball size */
  .static-basketball {
    font-size: 24px;
    margin: 0 5px;
  }

  /* Reduce subtitle font size */
  .header-subtitle {
    font-size: 14px;
    margin-top: 5px;
  }

  /* Adjust Home button size */
  .go-home-button {
    width: 60px;
    height: 60px;
    left: 10px;
    top: 10px;
  }

  .go-home-button .home-icon {
    font-size: 24px;
  }

  /* Adjust header-right position */
  .header-right {
    right: 10px;
    top: 10px;
    transform: none;
    /* Changed flex-direction to column for vertical stacking */
    flex-direction: column;
    gap: 5px;
  }

  /* Adjust buttons for mobile */
  .nav-button,
  .legacy-button,
  .logout-button {
    padding: 8px 12px;
    font-size: 12px;
    height: auto;
    width: auto;
    box-shadow: none;
  }

  /* Adjust stacked-buttons for mobile */
  .stacked-buttons {
    /* Changed flex-direction to column for vertical stacking */
    flex-direction: column;
    height: auto;
    gap: 5px;
  }

  /* Hide interactive elements on mobile */
  .basketball,
  .hoop,
  .basketball-placeholder,
  .hoop-placeholder {
    display: none;
  }
}

/* For devices with width up to 430px */
@media only screen and (max-width: 430px) {
  /* Further reduce header padding */
  .header-container {
    padding: 10px 5px;
  }

  /* Reduce title font size */
  .header-title {
    font-size: 20px;
  }

  /* Adjust static basketball size */
  .static-basketball {
    font-size: 20px;
    margin: 0 3px;
  }

  /* Reduce subtitle font size */
  .header-subtitle {
    font-size: 12px;
  }

  /* Adjust Home button size */
  .go-home-button {
    width: 50px;
    height: 50px;
    left: 5px;
    top: 5px;
  }

  .go-home-button .home-icon {
    font-size: 20px;
  }

  /* Adjust header-right for smaller screens */
  .header-right {
    right: 5px;
    top: 5px;
    gap: 3px;
  }

  /* Adjust buttons for smaller mobile screens */
  .nav-button,
  .legacy-button,
  .logout-button {
    padding: 6px 8px;
    font-size: 10px;
  }

  /* Adjust stacked-buttons for smaller screens */
  .stacked-buttons {
    gap: 3px;
  }
}
